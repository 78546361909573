import revive_payload_client_JjH8fd1Xet from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0GX47fgzGA from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vctzTbfFbo from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_gj6fEdegAA from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zEv9cxo0Jo from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NBIKJcrJaS from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5VE9mNKd0u from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_soxci6bR5e from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.21.2_typescript@5.6.2_vue@3.5.4_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/.nuxt/components.plugin.mjs";
import prefetch_client_zai6OTxJ4c from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.50_eslint@8.57.0_ioredis@5.4.1_magicast@0_bowb55cx2v63qu5m7fdcfyisvi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vueformPlugin_kEcARWMiqX from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/.nuxt/vueformPlugin.mjs";
import plugin_KEOZPL8DyK from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/dayjs-nuxt@1.2.7_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import slideovers_BO95vvq4rI from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/@nuxt+ui@2.18.4_async-validator@4.2.5_axios@1.7.7_magicast@0.3.5_qrcode@1.5.4_rollup@4.21.2_u_r2hjfdutnawlyvzjjus5vzenhe/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_baaiIhONjs from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/@nuxt+ui@2.18.4_async-validator@4.2.5_axios@1.7.7_magicast@0.3.5_qrcode@1.5.4_rollup@4.21.2_u_r2hjfdutnawlyvzjjus5vzenhe/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_G28zNyneQO from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/@nuxt+ui@2.18.4_async-validator@4.2.5_axios@1.7.7_magicast@0.3.5_qrcode@1.5.4_rollup@4.21.2_u_r2hjfdutnawlyvzjjus5vzenhe/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ku775Rc9Bz from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_O2KRrCm1Mo from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.4_@types+node@18.19.50_sass@1.78.0_ter_nuqbfpx7azlaf3elimq5g4kei4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_A9EEwqYlCu from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.21.2_types_bwtvwyiwwusizs5epcn63pmv24/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import auth_e0FkfVuy48 from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/plugins/auth.ts";
import error_t9vrANEheK from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/plugins/error.ts";
import repositories_Svc5U6rUsm from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/plugins/repositories.ts";
import sentry_h0fMjCG9AB from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/plugins/sentry.ts";
import supabase_client_Vu6b6nytQn from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/plugins/supabase.client.ts";
import vue_toastificaton_client_YIlzAhW63j from "/home/anh/project/outsource/alobacsi/clinic booking/user-web-app/src/plugins/vue-toastificaton.client.ts";
export default [
  revive_payload_client_JjH8fd1Xet,
  unhead_0GX47fgzGA,
  router_vctzTbfFbo,
  payload_client_gj6fEdegAA,
  navigation_repaint_client_zEv9cxo0Jo,
  check_outdated_build_client_NBIKJcrJaS,
  chunk_reload_client_5VE9mNKd0u,
  plugin_vue3_soxci6bR5e,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zai6OTxJ4c,
  vueformPlugin_kEcARWMiqX,
  plugin_KEOZPL8DyK,
  slideovers_BO95vvq4rI,
  modals_baaiIhONjs,
  colors_G28zNyneQO,
  plugin_client_ku775Rc9Bz,
  plugin_O2KRrCm1Mo,
  plugin_A9EEwqYlCu,
  auth_e0FkfVuy48,
  error_t9vrANEheK,
  repositories_Svc5U6rUsm,
  sentry_h0fMjCG9AB,
  supabase_client_Vu6b6nytQn,
  vue_toastificaton_client_YIlzAhW63j
]